//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import clone from 'lodash/clone'

export default {
  data() {
    return {
      isMoreShow: false,
      moreMenu: [],
      moreMenuInOrder: [],
      mainNonActive: null,
      mainMenu: []
    }
  },
  async fetch() {
    const navMenu = [
      {
        name: 'Dashboard',
        icon: 'bill-sclvico',
        link: '/dashboard',
        hasPermission: true
      },
      {
        name: 'Product',
        icon: 'cube-sclvico',
        link: '/products',
        hasPermission: false
      },
      {
        name: 'Landing Page',
        icon: 'doc-sclvico',
        link: '/pages',
        hasPermission: false
      },
      {
        name: 'Order',
        icon: 'bill-sclvico',
        link: '/order',
        hasPermission: false
      },
      {
        name: 'Bundle',
        icon: 'cube-collection-2-sclvico',
        link: '/bundles',
        hasPermission: false
      },
      {
        name: 'Balance',
        icon: 'cash-sclvico',
        link: '/balance',
        hasPermission: false
      },
      {
        name: 'Store',
        icon: 'store-sclvico',
        link: '/store',
        hasPermission: false
      },
      {
        name: 'Customer',
        icon: 'user-group-sclvico',
        link: '/customer',
        hasPermission: false
      },
      {
        name: 'Inventory',
        icon: 'cube-collection-sclvico',
        link: '/inventory',
        hasPermission: false
      },
      {
        name: 'Analytics',
        icon: 'bar-chart-sclvico',
        link: '/analytics',
        hasPermission: true
      },
      {
        name: 'Ongkir',
        icon: 'ic-shipping',
        link: '/shipping-fee',
        hasPermission: true
      },
      {
        name: 'Setting',
        icon: 'ic-config',
        link: '/setting',
        hasPermission: true
      },
      {
        name: 'Support',
        icon: 'support-sclvico',
        link: '/support',
        hasPermission: true
      },
      {
        name: 'Tutorial',
        icon: 'information-sclvico',
        link: '/tutorial',
        hasPermission: true
      },
      this.$auth.user.detail.current_business_subscription.current_pricing_plan
        .subscription_plan.code === 'basic'
        ? {
            name: 'Upgrade',
            icon: 'upgrade-sclvico',
            link: '#',
            hasPermission: true
          }
        : undefined,
      {
        name: 'Woi!',
        icon: 'bell-sclvico',
        link: '#',
        hasPermission: true
      },
      {
        name: 'Affiliate',
        icon: 'heart-outline-sclvico',
        link: '/affiliate',
        hasPermission: true
      }
    ].filter((x) => x !== undefined)
    const menu = await navMenu.reduce(async (fixList, menu, i) => {
      let hasPermission = menu.hasPermission
      if (menu.name === 'Product') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: ['view_product']
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Bundle') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: ['view_bundle']
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Inventory') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: 'view_inventory_flow'
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Order') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: 'view_order'
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Customer') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: 'view_customer'
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Store') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: 'list_store'
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Landing Page') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: ['view_form', 'view_page']
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Ongkir') {
        menu.hasPermission = true
      } else if (menu.name === 'Balance') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: ['view_business_transaction']
        })
        menu.hasPermission = hasPermission
      } else if (menu.name === 'Upgrade') {
        hasPermission = await this.$store.dispatch('permission/hasPermission', {
          user: this.$auth.user,
          permission: ['edit_business']
        })
        menu.hasPermission = hasPermission
      }
      if (menu.hasPermission) {
        return [...(await fixList), ...(menu.hasPermission ? [menu] : [])]
      }
      return await fixList
    }, [])
    this.moreMenuInOrder = menu.splice(4)
    this.moreMenuInOrder = this.moreMenuInOrder.map((menuNa, index) => ({
      ...menuNa,
      index
    }))
    this.moreMenu = clone(this.moreMenuInOrder)
    this.mainMenu = menu
    this.mainNonActive = clone(this.mainMenu[3])
    const moreMenuIsActive = this.moreMenu.findIndex(
      (menuNa) => menuNa.link === this.$route.path
    )
    if (moreMenuIsActive >= 0) {
      this.onNavigateMore(this.moreMenu[moreMenuIsActive])
    }
  },
  methods: {
    onNavigateMore(clickedMenu) {
      if (!clickedMenu.name === 'Woi!' && !clickedMenu.name === 'Upgrade') {
        const moreMenuOri = clone(this.moreMenuInOrder)
        if (this.mainNonActive.name === clickedMenu.name) {
          this.mainMenu.splice(3, 1, this.mainNonActive)
          this.isMoreShow = false
        } else {
          this.mainMenu.splice(3, 1, clickedMenu) // replace main menu
          this.isMoreShow = false
          moreMenuOri.splice(clickedMenu.index, 1) // remove
          moreMenuOri.splice(0, 0, this.mainNonActive)
        }
        this.moreMenu = moreMenuOri
      } else {
        this.isMoreShow = false
      }
    },
    route(link) {
      if (
        this.mainMenu.length > 4 &&
        this.mainMenu[3].name !== this.mainNonActive.name
      ) {
        this.onNavigateMore(this.mainNonActive)
      } else if (this.moreMenu[0].name === this.mainNonActive.name) {
        this.moreMenu.splice(0, 1, this.mainMenu[3])
        this.mainMenu.splice(3, 1, this.mainNonActive)
      }
      this.$router.push(link)
    }
  }
}
