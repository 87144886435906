//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { onChangeCompanyBusiness } from '@/utils/helpers.js'

export default {
  name: 'Dashboard',
  data() {
    return {
      year: new Date().getFullYear(),
      isCancel: false,
      isSuccess: true,
      isPermissionListModalShown: false,
      isAuthModalShown: false,
      nextPlan: null,
      titleChangePlanConfirmModal: 'Berhenti Berlangganan',
      localStorageInterval: null,
      canEditBusiness: false,
      isBannedShown: true,
      isFreeBannerShown: true
    }
  },
  async fetch() {
    this.canEditBusiness = await this.$store.dispatch(
      'permission/hasPermission',
      { user: this.$auth.user, permission: 'edit_business' }
    )
  },
  head() {
    return {
      bodyAttrs: {
        class: 'bg-gray'
      },
      titleTemplate: '%s | Scalev',
      script: [
        {
          type: 'text/javascript',
          innerHTML: `
            window.$crisp=[];window.CRISP_WEBSITE_ID="0fe3d096-9469-42a4-be4b-77edbea0f578";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();
          `
        },
        {
          innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.$config.scalevGtm}');`,
          type: 'text/javascript'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('common', [
      'getNavbarShow',
      'getMainHeaderShow',
      'getDesktopHeaderColor',
      'getBusinessAddModalShow',
      'getBusinessModalCancelShow',
      'getBusinessModalSuccessShow',
      'getBusinessAccountPhoneShow',
      'getInvitationShow',
      'getFormChangedModalShow',
      'getFormChangedNextObj',
      'getBusinessListSelectorShow',
      'getImageDeleteConfirmationShow',
      'getImageDeleteCb',
      'getImageViewerShow',
      'getImageToShow',
      'getHasLatestVer'
    ]),
    ...mapGetters('subscription', [
      'getPricePlanShow',
      'getChangePlanConfirmShow'
    ]),
    isWarningsAvailable() {
      return (
        (this.$auth.user.detail.current_business_subscription
          .current_pricing_plan &&
          this.$auth.user.detail.current_business_subscription
            .current_pricing_plan.subscription_plan.monthly_order_limit &&
          this.orderLimitUsage >= 80) ||
        (this.$auth.user.currentBusiness.subscription &&
          this.$auth.user.currentBusiness.subscription
            .latest_subscription_order &&
          this.$auth.user.currentBusiness.subscription.latest_subscription_order
            .status === 'open') ||
        (this.$auth.user.currentBusiness.subscription &&
          this.$auth.user.currentBusiness.subscription.status === 'unpaid')
      )
    },
    getPermissions() {
      try {
        const permissionsList = cloneDeep(
          this.$auth.user.account.role.permissions_list
        )
        return permissionsList
      } catch {
        return []
      }
    },
    getUserHasBusiness() {
      return !!this.$auth.user.detail?.current_business
    },
    getBusinessDetail() {
      return this.$auth.user.currentBusiness || {}
    },
    isBusinessShow: {
      get() {
        return this.getBusinessListSelectorShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessListSelectorShow', val)
      }
    },
    isInvitationModalShow: {
      get() {
        return this.getInvitationShow
      },
      set(val) {
        this.$store.dispatch('common/setInvitationShow', val)
      }
    },
    isPricePlanModalShow: {
      get() {
        return this.getPricePlanShow
      },
      set(val) {
        this.$store.dispatch('subscription/setPricePlanShow', val)
      }
    },
    isChangePlanConfirmModalShow: {
      get() {
        return this.getChangePlanConfirmShow
      },
      set(val) {
        this.$store.dispatch('subscription/setChangePlanConfirmShow', val)
      }
    },
    isBusinessAccountPhoneModalShow: {
      get() {
        return this.getBusinessAccountPhoneShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAccountPhoneShow', val)
      }
    },
    isAddModalShow: {
      get() {
        return this.getBusinessAddModalShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessAddModalShow', val)
      }
    },
    isSuccessBusiness: {
      get() {
        return this.getBusinessModalSuccessShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessModalSuccess', val)
      }
    },
    isCancelModalShow: {
      get() {
        return this.getBusinessModalCancelShow
      },
      set(val) {
        this.$store.dispatch('common/setBusinessModalCancel', val)
      }
    },
    desktopHeaderColor: {
      get() {
        return this.getDesktopHeaderColor
      },
      set(val) {
        this.$store.dispatch('common/setDesktopHeaderColor', val)
      }
    },
    isFormChangedModalShow: {
      get() {
        return this.getFormChangedModalShow
      },
      set(val) {
        this.$store.dispatch('common/setFormChangedModalShow', val)
      }
    },
    formChangedNextObj: {
      get() {
        return this.getFormChangedNextObj
      },
      set(val) {
        this.$store.dispatch('common/setFormChangedNextObj', val)
      }
    },
    isMembershipLocked() {
      return (
        this.$auth?.user?.currentBusiness &&
        ((this.$auth?.user.currentBusiness.invitationStatus === 'accepted' &&
          !this.$auth?.user?.currentBusiness?.isUserBusinessActive) ||
          this.$auth?.user?.account.is_registering)
      )
    },
    isMembershipRegistering() {
      return (
        this.$auth?.user?.currentBusiness &&
        this.$auth?.user?.currentBusiness?.isUserBusinessActive &&
        this.$auth?.user?.account.is_registering
      )
    },
    lockedWhitelistPage() {
      return [
        'setting',
        'setting-profile',
        'setting-profile-edit',
        'setting-profile-change-password'
      ].includes(this.$route.name)
    },
    isImageViewerShow: {
      get() {
        return this.getImageViewerShow
      },
      set(val) {
        this.$store.dispatch('common/setImageViewerShow', val)
      }
    },
    isImageDeleteConfirmationShow: {
      get() {
        return this.getImageDeleteConfirmationShow
      },
      set(val) {
        this.$store.dispatch('common/setImageDeleteConfirmationShow', val)
      }
    },
    imageToShow: {
      get() {
        return this.getImageToShow
      },
      set(val) {
        this.$store.dispatch('common/setImageToShow', val)
      }
    },
    currentPlan() {
      return (
        this.$auth.user?.detail?.current_business_subscription
          ?.current_pricing_plan || null
      )
    }
  },
  watch: {
    // $route(to, from) {
    //   const refreshToken = localStorage.getItem('auth._refresh.customLocal')
    //   if (!refreshToken || refreshToken === 'false') {
    //     location.reload()
    //   }
    // },
    '$route.path'(val, oldVal) {
      if (oldVal !== val) {
        this.desktopHeaderColor = ''
      }
      if (val === '/dashboard') {
        // nothing
      }
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(
        'subscription/fetchById',
        this.$auth.user.currentBusiness.subscription.id
      )
    } catch (err) {
      console.error('failed to fetch subscription detail')
    }
    this.$i18n.setLocale('id')
    this.$nextTick(() => {
      if (!this.getPricePlanShow) {
        // nothing
      }
      // eslint-disable-next-line no-undef
      $crisp.push(['set', 'user:nickname', [this.$auth.user.detail.fullname]])
      // eslint-disable-next-line no-undef
      $crisp.push(['set', 'user:email', [this.$auth.user.detail.email]])
      // eslint-disable-next-line no-undef
      $crisp.push(['set', 'user:phone', [this.$auth.user.detail.phone]])
      // eslint-disable-next-line no-undef
      $crisp.push(['set', 'user:avatar', [this.$auth.user.detail.avatar]])
      // eslint-disable-next-line no-undef
      $crisp.push([
        'set',
        'user:company',
        [
          `[${
            this.$auth.user.currentBusiness?.subscription?.current_pricing_plan
              ?.code === 'basic'
              ? 'FREE'
              : this.$auth.user.currentBusiness?.subscription
                  ?.current_pricing_plan?.subscription_plan?.name
          }] ${this.$auth.user.currentBusiness.account_holder}`,
          {}
        ]
      ])
      this.initializeWs()
    })
  },
  destroyed() {
    document.querySelector('#sclv-modal-wrapper').innerHTML = ''
    clearInterval(this.localStorageInterval)
  },
  methods: {
    routeToBilling() {
      // eslint-disable-next-line no-undef
      dataLayer.push({
        event: 'free_user_upgrading'
      })

      if (this.$route.path === '/setting/billing') {
        this.isPricePlanModalShow = true
      } else {
        this.$router.push('/setting/billing?isPricePlanShown=true')
      }
    },
    showWarnings() {
      const sne = new Event('showWarnings')
      document.dispatchEvent(sne)
    },
    daysDifference(dateString) {
      const inputDate = new Date(dateString)
      const today = new Date()

      const differenceInTime = inputDate - today

      const differenceInDays = Math.floor(
        differenceInTime / (1000 * 60 * 60 * 24)
      )

      return differenceInDays < 0 ? 0 : differenceInDays
    },
    initializeWs() {
      this.$notif.initSocket()
      this.$notif.socket.connect()
      this.$notif.setupSclvNotifications()
      this.$notif.socket.onOpen(() => {
        this.$notif.socket.off('onOpen')
        this.$notif.socket.onOpen(async () => {
          await this.$notif.updateTokenAndReconnect()
        })
      })
    },
    async logout() {
      this.$nuxt.$loading.start(true)
      // eslint-disable-next-line no-undef
      if ($crisp) {
        // eslint-disable-next-line no-undef
        $crisp.push(['do', 'session:reset'])
      }
      await this.$auth.logout()
      location.href = '/login'
    },
    reloadPage() {
      window.location.reload()
    },
    onChangeCompanyBusiness,
    onAddBusiness() {
      // nothing
    },
    addBusinessSucceed() {
      this.isAddModalShow = false
      this.isSuccess = true
      this.onChangeCompanyBusiness()
    },
    editBusinessAccountSucceed() {
      this.isBusinessAccountPhoneModalShow = false
    },
    async goToUrlPromt() {
      this.formChangedNextObj()
      await Promise.all([
        this.$store.dispatch('common/setFormChangedModalShow', false),
        this.$store.dispatch('common/setFormChanged', false)
      ])
    },
    yesCancel() {
      this.isCancel = false
      this.isAddModalShow = false
    },
    noCancel() {
      this.isCancel = false
      this.isBusinessAddModelShow = true
    },
    showChangePlanConfirmModal(nextPlan) {
      this.nextPlan = nextPlan
      this.titleChangePlanConfirmModal = 'Pilih Paket Subscription'
      this.isChangePlanConfirmModalShow = true
    },
    async changePlan() {
      this.$nuxt.$loading.start(true)
      try {
        let resUpdateSubscription = null
        const payloadPayment = {
          idSubscription:
            this.$auth.user?.detail?.current_business_subscription?.id,
          pricingPlanCode: this.nextPlan.code
        }
        if (this.$auth.user?.detail?.current_business_subscription) {
          resUpdateSubscription = await this.$store.dispatch(
            'subscription/partialUpdateSubscription',
            {
              idSubscription:
                this.$auth.user?.detail?.current_business_subscription?.id,
              pricingPlanCode: this.nextPlan.code
            }
          )
        } else {
          resUpdateSubscription = await this.$store.dispatch(
            'subscription/storeData',
            payloadPayment
          )
        }
        let titleToast = 'dipilih'
        if (
          this.currentPlan !== null &&
          this.currentPlan.price > this.nextPlan.price
        ) {
          titleToast = 'diturunkan'
        } else if (
          this.currentPlan !== null &&
          this.currentPlan.price > this.nextPlan.price
        ) {
          titleToast = 'ditingkatkan'
        }
        this.$toast.showMessage({
          message: 'Paket Berlangganan Berhasil ' + titleToast,
          color: 'success'
        })

        if (
          resUpdateSubscription?.latest_subscription_order?.status === 'open'
        ) {
          setTimeout(() => {
            window
              .open(
                resUpdateSubscription.latest_subscription_order
                  .xendit_invoice_url,
                '_blank'
              )
              .focus()
          })
        }
        await this.$auth.fetchUser()
        await this.$store.dispatch('subscription/fetchAll', {
          isFirst: true
        })
        this.isChangePlanConfirmModalShow = false
        this.isPricePlanModalShow = false
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.$nuxt.$loading.finish()
      }
    },
    onCloseModalPricePlan() {
      this.isPricePlanModalShow = false
      this.$router.push('/setting/billing')
    },
    acceptInvitation() {},
    declineInvitation() {}
  }
}
